import React from 'react';
import PropTypes from 'prop-types';
import theme from 'UI/globals/theme';
import { Select } from '../../../globals/Select/core__select';
import { ThemeProvider } from 'styled-components';
import { GREYS, FONT, BRAND } from 'UI/globals/colours';
import { SelectStyles } from 'UI/lines/dropdowns/OddsSelectStyles';

const select_styles = SelectStyles || {};

const styles = {
  color: `${FONT.dark}`,
  fontFamily: `${theme.fontFamily}`,
  fontSize: '12px',
  margin: '0 16px',
  flex: '1',
  control: (provided, state) => ({
    ...provided,
    backgroundColor:
      state.selectProps.appTheme === 'dark'
        ? `${GREYS.black}`
        : `${BRAND.tertiaryL}`,
    position: 'absolute',
    width: '145px',
    top: '-8px',
    border: 'none',
    boxShadow: 'none',
    justifyContent: 'left',
    minHeight: 'auto',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: `${FONT.lightMute}`,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    backgroundColor: 'inherit',
    color: `${FONT.lightMute}`,
  }),
  menu: provided => ({
    ...provided,
    width: '10em',
  }),
  valueContainer: provided => ({
    ...provided,
    backgroundColor: `inherit`,
    justifyContent: 'left',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color:
      state.selectProps.appTheme === 'dark'
        ? `${FONT.lightMute}`
        : `${FONT.primary}`,
    cursor: ' pointer',
    paddingRight: '8px',
    ':after': {
      marginLeft: '8px',
      content: "''",
      border: `solid ${GREYS.black}`,
      borderWidth: ' 0 1px 1px 0',
      display: 'inline-block',
      padding: '4px',
      transform: 'rotate(45deg)',
      position: ' relative',
      top: '-2px',
    },
    textTransform: 'capitalize',
  }),
  menuList: (provided, state) => ({
    ...provided,
    background:
      state.selectProps.appTheme === 'dark'
        ? `${GREYS.black}`
        : `${BRAND.tertiaryL}`,
    color:
      state.selectProps.appTheme === 'dark'
        ? `${FONT.lightMute}`
        : `${FONT.primary}`,
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'center',
    backgroundColor: 'inherit',
    color: !state.selectProps.appTheme === 'dark' && BRAND.primary,
    ':hover': {
      background:
        state.selectProps.appTheme === 'dark'
          ? `${GREYS.silver}`
          : `${BRAND.primary}`,
      color: `${GREYS.white}`,
    },
    textTransform: 'capitalize',
  }),
  ...select_styles,
};

const OddsSelect = ({ ...props }) => {
  const {
    options,
    defaultValue,
    defaultLabel,
    onChange,
    menuIsOpen,
    menuPlacement,
    appTheme,
  } = props;
  return (
    <ThemeProvider theme={theme}>
      <Select
        instanceId={'id'}
        appTheme={appTheme}
        isSearchable={false}
        options={options}
        styles={styles}
        defaultValue={{ value: defaultValue, label: defaultLabel }}
        onChange={onChange && onChange}
        menuPlacement={(menuPlacement && menuPlacement) || `top`}
        menuIsOpen={menuIsOpen}
      ></Select>
    </ThemeProvider>
  );
};

OddsSelect.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  defaultLabel: PropTypes.string,
  onChange: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  menuPlacement: PropTypes.string,
  appTheme: PropTypes.string,
};

export default OddsSelect;
