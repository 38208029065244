import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import PubSub from '../../../services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import {
  createCookie,
  getCookie,
} from '../../../services/cookie/core__cookies';
import project from '../../../project';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';

const initialState = {
  OddsTypeReducer: {
    defaultValue: '',
    oddsType: [],
  },
};
export const OddsTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case 'DEFAULT_ODDS':
      return {
        ...state,
        defaultValue: action.oddsValue,
      };
    case 'ODDS_TYPE':
      return {
        ...state,
        oddsType: action.oddsType,
      };
    default:
      return state;
  }
};
export const reducer = combineReducers({
  OddsTypeReducer: OddsTypeReducer,
});
export const oddsTypes = oddsType => ({
  type: 'ODDS_TYPE',
  oddsType,
});
export const defaultOdds = oddsValue => ({
  type: 'DEFAULT_ODDS',
  oddsValue,
});
export const onOddsChange = (selectedOdds, oddsType) => dispatch => {
  const val =
    oddsType.indexOf(selectedOdds.toLowerCase()) >= 0
      ? selectedOdds.toLowerCase()
      : project?.defaultOddsType || GLOBAL_CONSTANTS.FRACTIONAL;

  dispatch(defaultOdds(val));
  PubSub.emit(PubsubEvents.oddsValue, val);
  createCookie('selectedOdds', val);
};
export const getOddType = (oddsType, cookie, dispatch) => {
  dispatch(oddsTypes(oddsType));
  let cookieValue = cookie['selectedOdds']
    ? cookie['selectedOdds']
    : getCookie('selectedOdds');
  if (cookieValue === false) {
    cookie
      ? (cookie['selectedOdds'] = oddsType[0].toLowerCase())
      : createCookie('selectedOdds', oddsType[0].toLowerCase());
    cookieValue = oddsType[0].toLowerCase();
    PubSub.emit(PubsubEvents.oddsValue, cookieValue);
  }
  dispatch(defaultOdds(cookieValue));
};

const composeEnhancers = getComposeEnhancers({ name: 'odds-selection-store' });

export default () =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
