import styled from 'styled-components';
import { Select } from '../../../globals/Select/core__select';
import theme from 'UI/globals/theme';
import { GREYS, FONT } from 'UI/globals/colours';
export const StyledSelect = styled(Select)`
  color: ${FONT.dark};
  font-family: ${theme.fontFamily};
  font-size: 12px;
  margin: 0 16px;
  flex: 1;
  text-transform: capitalize;
  div[class*='singleValue'] {
    cursor: pointer;
    padding-right: 8px;
    &:after {
      margin-left: 8px;
      content: '';
      border: solid ${GREYS.black};
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      position: relative;
      top: -2px;
    }
  }
`;
