import React from 'react';
import PropTypes from 'prop-types';
import { OD } from 'UI/apps/OddsSelectionApp/oddsSelectionReact';
import OddsSelect from 'UI/lines/dropdowns/OddsSelect';
import { Translations } from '../core__oddsSelection-app';
class OddsSelections extends React.Component {
  constructor(props) {
    super(props);
    const oddsTypeConfig = props.appConfig;
    const oddsSelections = oddsTypeConfig?.oddstypes
      ? oddsTypeConfig.oddstypes.split(',')
      : ['fractional'];
    props.getOddType(oddsSelections, props.cookie, props.dispatch);
    this.state = {
      theme: props.appConfig?.theme,
      appConfig: props.appConfig,
    };
  }

  // to set default value of cookies when component mount
  componentDidMount() {
    const { oddsType, onOddsChange, defaultValue } = this.props;
    this.props.dispatch(onOddsChange(defaultValue, oddsType));
  }

  render() {
    const { oddsType, onOddsChange, defaultValue } = this.props;

    return (
      <OD>
        {Translations.get('text.odds')}:
        {defaultValue && (
          <OddsSelect
            appTheme={this.state.theme}
            data-test="odds-change"
            defaultLabel={Translations.get(defaultValue)}
            defaultValue={defaultValue}
            onChange={value =>
              this.props.dispatch(onOddsChange(value?.value, oddsType))
            }
            options={oddsType.map(data => {
              return {
                label: Translations.get(data.toLowerCase()),
                value: data,
              };
            })}
          />
        )}
      </OD>
    );
  }
}
OddsSelections.propTypes = {
  appConfig: PropTypes.object,
  oddsType: PropTypes.array,
  onOddsChange: PropTypes.func,
  defaultValue: PropTypes.string,
  getOddType: PropTypes.func,
  appTheme: PropTypes.string,
  dispatch: PropTypes.func,
  cookie: PropTypes.object,
};
export default OddsSelections;
