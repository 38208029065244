import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { connect } from 'react-redux';
import { getCookie } from 'Services/cookie/core__cookies';
import createStore, {
  onOddsChange,
  getOddType,
} from './core__oddsSelection-store';
import OddsSelection from './component/core__oddsSelectionReact';
import ScDecoder from 'Services/json/core__decoder';

import { TranslationsProvider } from 'Services/translations/core__translations';
import { translationString } from './core__oddsSelection-translation.js';
export const Translations = new TranslationsProvider('OddsSelectionApp');
class OddsSelectionApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }
  start(data, reduxSelector) {
    Translations.setAll(data);
    const ReactInstance = this.getReactApp(this.cookies);
    const jsx = (
      <ReduxProvider
        store={createStore(data || { data: { cookies: this.cookies } })}
      >
        <ReactInstance />
      </ReduxProvider>
    );
    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }
  getReactApp(cookies) {
    const cookie = cookies;
    const cookieValue = cookie
      ? cookie['selectedOdds']
      : getCookie('selectedOdds')
      ? getCookie('selectedOdds')
      : this.appConfig?.oddstypes?.split(',')[0].toLowerCase();
    const oddsTypeVal = this.appConfig?.oddstypes
      ? this.appConfig.oddstypes.split(',')
      : ['fractional'];
    const mapStateToProps = state => ({
      appConfig: this.appConfig,
      oddsType: oddsTypeVal ? oddsTypeVal : state.OddsTypeReducer.oddsType,
      defaultValue: cookieValue
        ? cookieValue
        : state.OddsTypeReducer.defaultValue,
      isshowOdds: state.OddsTypeReducer.isshowOdds,
      cookie: cookie,
    });
    const mapDispatchToProps = dispatch => ({
      onOddsChange: onOddsChange,
      getOddType: getOddType,
      dispatch,
    });
    OddsSelection.serverFetch = {
      createStore,
      fetchData: () => dispatch => {
        return new Promise(resolve => {
          dispatch({ type: 'DO_NOTHING' });
          resolve();
        });
      },
      strToTranslate: translationString,
    };
    return connect(mapStateToProps, mapDispatchToProps)(OddsSelection);
  }
}
export default OddsSelectionApp;
